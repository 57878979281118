.container-token-sale {
  display: flex;
  justify-content: center;
  // margin-top: 40px;
  background-color: #addada;
  border-radius: 15px;
  padding-top: 40px;
  > .box-sale {
    min-width: 800px;
    border-radius: 15px;
    // background-color: #5bacce;
    // background-color: #7CCECE;
    // background-color: #fff;

    min-height: 1000px;
    padding: 20px;
    > .box-sale-top {
      display: grid;
      grid-template-columns: 6fr 6fr;
      column-gap: 20px;
      > .box-sale-top-img {
        height: 350px;
        background-color: #fff;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        overflow: hidden;
        > img {
          border-radius: 12px;
          object-fit: cover;
          object-position: center;
        }
      }
      > .box-sale-top-detail {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        > .box-sale-top-detail-title {
          text-align: center;
          // color: #fff;
        }
        > .box-sale-top-detail-fields {
          margin-top: 15px;
          display: grid;
          grid-template-columns: 6fr 6fr;
          column-gap: 10px;
          font-size: 18px;
          :last-child {
            text-align: end;
          }
          // color: #fff;
        }
      }
    }
    > .box-sale-center {
      display: grid;
      grid-template-columns: 6fr 6fr;
      column-gap: 20px;
      margin-top: 20px;
      & .pay-with-token {
        background-color: #fff;
        border-radius: 15px;
        padding: 15px;
        > .pay-with-token-title {
          text-align: center;
          font-size: 18px;
        }
        > .pay-with-token-form {
          display: flex;
          column-gap: 10px;
          margin-top: 20px;
          > .box-approve {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
          & .custom-input-number {
            > .rs-input {
              text-align: end;
              border-radius: 6px;
            }
            .rs-input-number-btn-group-vertical {
              display: none;
            }
          }
        }
        > .pay-with-token-balance {
          margin-top: 10px;
        }
      }
      & .pay-with-fiat {
        background-color: #fff;
        border-radius: 15px;
        padding: 15px;
        margin-top: 20px;
        > .pay-with-fiat-title {
          text-align: center;
          font-size: 18px;
        }
        > .pay-with-fiat-form {
          display: flex;
          column-gap: 10px;
          margin-top: 20px;
          & .custom-input-number {
            > .rs-input {
              text-align: end;
              border-radius: 6px;
            }
            .rs-input-number-btn-group-vertical {
              display: none;
            }
          }
        }
      }
    }
  }
}

.big-test {
  & .otp-box {
    > input {
      width: 2.4rem;
    }
  }
}
